<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
        <banner
          title="Services"
          :breadcrumb="[
            {
              label: 'Services',
              url: 'http://localhost:8080/super-admin/admins',
            },
            { label: 'Packages Services' },
          ]"
        >
        </banner>

        <div v-if="loading == true">
          <Spinner />
        </div>

        <div class="row" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div
              class="card radius"
              style="box-shadow: 0px 0px 10px gray; margin-left: 3px; height: 100%"
            >
              <div class="card-body">
                <!-- start list area  -->
                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <h4>
                      <b style="color: #00364f; font-weight: bolder">Services </b>
                    </h4>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-end"></div>

                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm">
                        <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                          <th>Code</th>
                          <th>Service Name</th>

                          <th>Package</th>

                          <th>Status</th>
                          <!-- <th>Action</th> -->
                        </tr>

                        <tbody>
                          <tr
                            style="color: #00364f"
                            v-for="(pkg, index) in packages"
                            :key="index"
                          >
                            <td class="text-truncate">
                              <span v-if="pkg.service">
                                {{ pkg.service.code }}
                              </span>
                            </td>
                            <td class="text-truncate">
                              <span v-if="pkg.service">
                                {{ pkg.service.name }}
                              </span>
                            </td>

                            <td>
                              <span v-if="pkg.package">
                                {{ pkg.package.package }}
                              </span>
                            </td>

                            <td>
                              <span v-if="pkg.service">
                                <p
                                  v-if="pkg.service.status == 'true'"
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: green;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b> Active</b>
                                </p>
                                <p
                                  v-else
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: red;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b> InActive</b>
                                </p>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end list area -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>
</template>

<script>
import Banner from "../../../components/retailer/comman/Banner.vue";
import Spinner from "../../../components/admin/comman/Spinner.vue";
// import Form from "vform";

// var _ = require('lodash');
// import jQuery from "jquery";
// let $ = jQuery
export default {
  name: "Admins",

  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      loading: false,
      qry: "",
      retailersFilter: [],
      packagesFilter: [],
      packages: [],
      statusQuery: null,
    };
  },
  methods: {
    valueAsign(event, qry) {
      this.statusQuery = event.target.value;
      this.qry = qry;
      // alert(this.statusQuery)
      this.filetr();
    },
    filetr() {
      if (this.statusQuery == null) {
        this.loadRetailersFilter();
      } else if (this.statusQuery != null) {
        var q = this.qry + "=" + this.statusQuery;
        this.loading = true;
        this.$axios
          .get(`admin/retailerpackage?${q}`, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            this.packages = res.data.data.data;
          })
          .finally(() => (this.loading = false));
      }
    },

    loadpPackagesFilter() {
      this.loading = true;
      this.$axios
        .get("admin/package", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.packagesFilter = res.data.data.data;
        })
        .finally(() => (this.loading = false));
    },
    loadRetailersFilter() {
      // alert('hdaskjhaskjdhasdkjhasdkh')
      this.loading = true;
      this.$axios
        .get("admin/retailer", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.retailersFilter = res.data.data.data;
        })
        .finally(() => (this.loading = false));
    },

    loadServices() {
      this.loading = true;
      this.$axios
        .get("admin/retailer", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.retailersFilter = res.data.data.data;
        })
        .finally(() => (this.loading = false));
    },

    loadRetailerPackages() {
      // alert('hdaskjhaskjdhasdkjhasdkh')
      this.$axios
        .get("admin/packageservice?all=all", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data);
          this.packages = res.data.data;
        });
    },
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      // this.loadServices();
      this.loadRetailerPackages();
      // this.loadRetailersFilter();
      // this.loadpPackagesFilter();
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  width: 95%;
  background-color: white;
  border-radius: 20px;
  box-shadow: 2px 2px 8px gray;
  line-height: 10%;
  margin-left: 13px;
}

.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
</style>
